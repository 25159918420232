import React, { useEffect, useRef } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from "@material-ui/core/InputLabel";


import api from 'services/apiInputService';

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardAvatar from 'components/Card/CardAvatar.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';

import avatar from 'assets/img/default-avatar.png';
import Badge from 'components/Badge/Badge';

// @material-ui/icons


import LoadingBar from 'react-top-loading-bar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SowTable from 'components/SOW/SowTable';
import WorkerTable from 'components/Worker/WorkerTable';
import { Logger } from 'helpers/LogHelper';
import { useSubscription } from 'hooks/useSubscription';
import { getRealmBySubscription } from 'helpers/SubscriptionHelper';
import { useKeycloak } from '@react-keycloak/web';
import { supplierRemap } from 'helpers/SupplierRemap';
import { useTranslation } from 'react-i18next';

import FieldglassInfo from 'components/FieldglassInfo/FieldglassInfo';
import { isCurrentSubscriptionFrom } from 'helpers/accountHelper';
import { useAuth } from 'hooks/useAuth';

const useStyles = makeStyles(styles);

export default function Profile() {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(['profile-commons', 'supplier-profile']);
  const classes = useStyles();
  const ref = useRef(null);
  const { userInfo } = useAuth();

  const [content, setContent] = React.useState([]);

  const [sowList, setSowList] = React.useState([]);
  const { subscription, subscriptionMini, subscriptionsAvailable } = useSubscription();

  console.log('content', content)

  useEffect(async () => {
    let supplierCode = userInfo?.customAttributes?.supplierCode;

    Logger('SupplierCode: ', supplierCode);

    const queryParams = new URLSearchParams(window.location.search);

    supplierCode = queryParams.get('id') ?? supplierCode;

    loadData('supplier', supplierCode);

  }, [subscription]);


  const loadData = (type, id) => {
    if (type === 'supplier') {
      Logger('Carregando Empresa...');
      api
        .get('/Supplier')
        .then(function (response) {
          const result = response.data.find((x) => x.supplierCode === id);
          setContent(result);
          ref.current.complete();
        })
        .catch(function (error) {
          Logger('Erro ao buscar perfil: ' + error);
        });
    }
  };


  return (
    <div>
      <LoadingBar color='#ff9800' ref={ref} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={8} style={{ marginBottom: '0px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '0px' }}>
              <Card>
                <CardHeader color='rose' icon>
                  <CardIcon color='rose'>
                    <PermIdentity />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>{t('profile')}</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      {content?.supplierName !== undefined ? (
                        <CustomInput
                          id='company-disabled'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: content.supplierName,
                            disabled: true,
                          }}
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      {content?.city !== undefined ? (
                        <CustomInput
                          id='username'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: content.city,
                            disabled: true,
                          }}
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {content?.supplierPrimaryContactPerson !== undefined ? (
                        <CustomInput
                          id='email-address'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: content.supplierPrimaryContactPerson,
                            disabled: true,
                          }}
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </GridItem>
                  </GridContainer>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
            <WorkerTable
            />
            {subscription == isCurrentSubscriptionFrom("KUO", subscription, subscriptionsAvailable) && <SowTable data={[...sowList]} />}
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt='...' />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>{content?.supplierName}</h4>
              {content?.status == 'Active' ? (
                <Badge color='success'>{t('status-active')}</Badge>
              ) : (
                <Badge color='danger'>{t('status-inactive')}</Badge>
              )}
              <p className={classes.description}>
                {t('supplier-profile-text', { ns: 'supplier-profile' })}
              </p>
              <Button
                color='warning'
                round
                disabled={!content?.zIdentifier}
                href={`${getRealmBySubscription(
                  subscription
                )}/supplier_detail.do?fromPage=detail&id=${content?.zIdentifier}`}
                target='_blank'
              >
                {t('access-fieldglass', { ns: 'supplier-profile' })}
              </Button>

              <FieldglassInfo
                title='Supplier Info'
                fieldGlassData={content}
                remapper={supplierRemap}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}