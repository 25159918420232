import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import { AddAlert, ArrowForwardIos, Group } from "@material-ui/icons";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import ReactTable from "components/ReactTable/ReactTable";
import { getRealmBySubscription } from "helpers/SubscriptionHelper";
import { useSubscription } from "hooks/useSubscription";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Snackbar from "components/Snackbar/Snackbar";
import { useWorkersRelatedQuery } from "services/input-core/workers/get-all-workers-related";
import { format } from "date-fns";
import { getCustomFieldFromWorker } from "helpers/workerHelpers";

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const workerOrderButtonProps: any = {
  justIcon: true,
  round: true,
  color: "warning",
  size: "sm",
  onClick: () => {
    return;
  },
};

interface WorkerTableProps {
  omit?: Array<string>;
}

const useStyles = makeStyles(styles);

const columns: any = [
  {
    Header: "worker-table-first-name",
    accessor: "firstName",
  },
  {
    Header: "worker-table-last-name",
    accessor: "lastName",
  },
  {
    Header: "worker-table-birth-date",
    accessor: "birthDate",
  },
  {
    Header: "worker-table-security-id",
    accessor: "securityID",
  },
  {
    Header: "worker-table-worker-id",
    accessor: "workerID",
  },
  {
    Header: "worker-table-sow-id",
    accessor: "sowID",
  },
  {
    Header: "worker-table-supplier-code",
    accessor: "supplierCode",
  },
  {
    Header: "worker-table-action",
    accessor: "actions",
  },
];

const gridItemProps: any = {
  xs: 12,
};

const WorkerRelated = ({ omit }: WorkerTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation("worker-profile");
  const navigate = useNavigate();
  const { subscription } = useSubscription();

  const currentWorker = new URLSearchParams(window.location.search).get("id");

  const {
    data: workersRelated,
    isFetching: isWorkersRelatedFetching,
    refetch,
  } = useWorkersRelatedQuery({
    workerID: currentWorker,
  });

  const [notification, setNotification] = useState({ open: false, message: "", color: "success" });

  const refetchTableData = (): void => {
    refetch();
  };

  useEffect(() => {
    if (subscription) refetchTableData();
  }, [subscription]);

  const columnsTranslated = columns.map((c: any) => ({
    Header: t(c.Header),
    accessor: c.accessor,
  }));

  const loadProfile = (profileId: string) => {
    navigate(`?type=worker&id=${profileId}`);
  };

  const columnsFiltered = columnsTranslated.filter((c: any) => !omit?.includes(c.accessor));

  const dateOfBirth = (prop: any) => {
    const value = getCustomFieldFromWorker(prop, "Date of birth");

    try {
      const date = new Date(value);
      const formattedValue = format(date, "dd/MM/yyyy");
      return formattedValue;
    } catch (error) {
      return "-";
    }
  };

  const workersRelatedFormatted =
    workersRelated?.map((prop) => ({
      firstName: prop.firstName ?? "",
      lastName: prop.lastName ?? "",
      birthDate: dateOfBirth(prop),
      securityID: prop.securityID ?? "",
      workerID: prop.workerID ?? "",
      sowID: prop.sowID ?? "Not Found",
      supplierCode: prop.vendorNumber,
      actions: (
        <div className='actions-right'>
          <Tooltip id='tooltip-top' title='View on Fieldglass'>
            <Button
              {...{
                ...workerOrderButtonProps,
                href: `${getRealmBySubscription(
                  subscription
                )}/profile_worker.do?tabId=manage&ref=profile_worker_manage&id=${
                  prop?.workerID
                }&cf=1`,
              }}
            >
              <ExitToAppIcon />
            </Button>
          </Tooltip>
          <Tooltip id='tooltip-worker' title="View worker's profile">
            <Button
              {...{
                ...workerOrderButtonProps,
                color: "primary",
                onClick: () => loadProfile(prop.workerID),
              }}
            >
              <ArrowForwardIos />
            </Button>
          </Tooltip>
        </div>
      ),
    })) ?? [];

  return (
    <GridItem {...gridItemProps}>
      {notification.open && (
        <Snackbar
          place='bc'
          color={notification.color}
          icon={AddAlert}
          message={notification.message}
          open={notification.open}
          closeNotification={() => setNotification((ps) => ({ ...ps, open: false }))}
          close
        />
      )}
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <Group />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Workers Related</h4>
        </CardHeader>
        <CardBody>
          {isWorkersRelatedFetching ? (
            <CircularProgress />
          ) : (
            <ReactTable
              columns={omit ? columnsFiltered : columnsTranslated}
              data={workersRelatedFormatted}
            />
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default WorkerRelated;
