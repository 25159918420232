import { makeStyles, Tooltip } from "@material-ui/core";
import { ArrowForwardIos, Group } from "@material-ui/icons";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Badge from "components/Badge/Badge";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import TabContent from "components/TabsRadix/TabContent";
import TabSystem, { Tab } from "components/TabsRadix/TabSystem";
import { getRealmBySubscription } from "helpers/SubscriptionHelper";
import { useSubscription } from "hooks/useSubscription";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useWorkerOrdersQuery } from "services/input-core/worker-orders/get-all-worker-orders";
import { useWorkersQuery } from "services/input-core/workers/get-all-workers";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import ApproveWorkOrderDialog from "components/ApproveWorkOrderDialog/ApproveWorkOrderDialog";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import EntityTable from "components/ReactTable/CustomTable";

import { ColumnDef } from "@tanstack/react-table";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { ProfileStatusEnum, SensitiveDataStatusEnum } from "utils/@types";

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles(styles);

const gridItemProps: any = {
  xs: 12,
};

const workerOrderButtonProps: any = {
  justIcon: true,
  round: true,
  color: "warning",
  size: "sm",
  onClick: () => {
    return;
  },
};

const getFilters = (filters: any) => {
  const object: { [key: string]: any } = {};

  if (filters?.length > 0)
    filters?.forEach((filter: any) => (object[`filter[${filter.id}]`] = filter.value));

  return { ...object };
};

const getSorting = (sorting: any) => {
  const object: { [key: string]: any } = {};

  if (sorting?.length > 0)
    sorting?.forEach((sort: any) => (object[`sort[${sort.id}]`] = sort.desc ? "desc" : "asc"));

  return { ...object };
};

function getBadgeFromStatus(status: string, t: any) {
  switch (status) {
    case SensitiveDataStatusEnum.PENDING_DATA:
    case SensitiveDataStatusEnum.PENDING_HR_FIELDS:
    case SensitiveDataStatusEnum.PENDING_OREP:
    case SensitiveDataStatusEnum.PENDING_APPROVAL:
      return <Badge {...{ color: "info" }}>{t(status)}</Badge>;
    case SensitiveDataStatusEnum.COMPLETED:
      return <Badge {...{ color: "success" }}>{t(status)}</Badge>;
    default:
      return <Badge {...{ color: "info" }}>{t(status)}</Badge>;
  }
}

function getBadgeFromEntityStatus(status: string, t: any) {
  switch (status) {
    case ProfileStatusEnum.PENDING:
    case ProfileStatusEnum.PENDING_APPROVAL:
      return <Badge {...{ color: "warning" }}>{t(status)}</Badge>;

    case ProfileStatusEnum.CONFIRMED:
    case ProfileStatusEnum.ACTIVE:
      return <Badge {...{ color: "success" }}>{t(status)}</Badge>;

    default:
      return <Badge {...{ color: "danger" }}>{t(status)}</Badge>;
  }
}

const columnsWorker: ColumnDef<any, any>[] = [
  {
    footer: (props) => props.column.id,
    header: "Work Order ID",
    accessorKey: "workOrderID",
    cell: (info) => info.getValue(),
  },
  {
    header: "Worker ID",
    footer: (props) => props.column.id,
    accessorKey: "workerID",
    cell: (info) => info.getValue(),
  },
  {
    header: "First name",
    footer: (props) => props.column.id,
    accessorKey: "firstName",
    cell: (info) => info.getValue(),
  },
  {
    header: "Last name",
    footer: (props) => props.column.id,
    accessorKey: "lastName",
    cell: (info) => info.getValue(),
  },
  {
    header: "Worker Status",
    footer: (props) => props.column.id,
    accessorKey: "status",
    cell: (info) => info.getValue(),
    enableSorting: false,
  },
  {
    header: "Vendor",
    footer: (props) => props.column.id,
    accessorKey: "vendor",
    cell: (info) => info.getValue(),
  },
  {
    header: "Actions",
    footer: (props) => props.column.id,
    accessorKey: "actions",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
  },
];

const columnsWorkOrder: ColumnDef<any, any>[] = [
  {
    footer: (props) => props.column.id,
    header: "Work Order ID",
    accessorKey: "workOrderID",
    cell: (info) => info.getValue(),
  },
  {
    header: "Worker ID",
    footer: (props) => props.column.id,
    accessorKey: "workerID",
    cell: (info) => info.getValue(),
  },
  {
    header: "First name",
    footer: (props) => props.column.id,
    accessorKey: "firstName",
    cell: (info) => info.getValue(),
  },
  {
    header: "Last name",
    footer: (props) => props.column.id,
    accessorKey: "lastName",
    cell: (info) => info.getValue(),
  },
  {
    header: "WO Status",
    footer: (props) => props.column.id,
    accessorKey: "status",
    cell: (info) => info.getValue(),
    enableSorting: false,
  },
  {
    header: "Data Status",
    footer: (props) => props.column.id,
    accessorKey: "sensitiveDataStatus",
    cell: (info) => info.getValue(),
    enableSorting: false,
  },
  {
    header: "Vendor",
    footer: (props) => props.column.id,
    accessorKey: "vendor",
    cell: (info) => info.getValue(),
  },
  {
    header: "Actions",
    accessorKey: "actions",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
  },
];

const WorkerTable = () => {
  const classes = useStyles();
  const { t } = useTranslation("worker-profile");
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const supplierCode = queryParams.get("id") ?? "";
  const { subscription } = useSubscription();

  const [workersFormatted, setWorkersFormatted] = useState([]);
  const [workOrdersFormatted, setWorkOrdersFormatted] = useState([]);

  const [workOrderTable, setWorkOrderTable] = useState({
    pageIndex: 0,
    pageSize: 10,
    columnFilters: [],
    sorting: [],
  });

  const [workerTable, setWorkerTable] = useState({
    pageIndex: 0,
    pageSize: 10,
    columnFilters: [],
    sorting: [],
  });

  const [openApproveWorkOrderDialogModal, setOpenApproveWorkOrderDialogModal] = useState({
    open: false,
  });

  const { watch, register } = useForm();

  const { data: workerOrders, isFetching: isWorkerOrdersLoading } = useWorkerOrdersQuery(
    subscription,
    {
      "page[index]": workOrderTable.pageIndex,
      "page[size]": workOrderTable.pageSize,
      "filter[onlyActive]": watch("showAllWorkOrders") ? 1 : 0,
      "filter[vendorNumber]": supplierCode ?? "",
      ...getFilters(workOrderTable.columnFilters),
      ...getSorting(workOrderTable.sorting),
    } as any
  );

  const { data: workers, isFetching: isWorkersLoading } = useWorkersQuery(subscription, {
    "page[index]": workerTable.pageIndex,
    "page[size]": workerTable.pageSize,
    "filter[vendorNumber]": supplierCode ?? "",
    ...getFilters(workerTable.columnFilters),
    ...getSorting(workerTable.sorting),
  } as any);

  useEffect(() => {
    if (workerOrders?.records) {
      setWorkOrdersFormatted(
        workerOrders?.records?.map((prop: any, key: any) => ({
          id: key,
          workerID: prop.workerID ?? "",
          workOrderID: prop.workOrderID ?? "",
          firstName: prop.firstName,
          lastName: prop.lastName,
          status: getBadgeFromEntityStatus(prop.workOrderStatus, t) ?? "",
          sensitiveDataStatus: prop.workerID
            ? getBadgeFromStatus(SensitiveDataStatusEnum.COMPLETED, t) ?? ""
            : getBadgeFromStatus(prop.currentSensitiveDataStatus, t) ?? "",
          vendor:
            prop.vendorNumber && prop.vendorName ? `${prop.vendorNumber} - ${prop.vendorName}` : "",
          actions: (
            <div className='actions-right'>
              <Tooltip id='tooltip-top' title='View on Fieldglass'>
                <Button
                  {...{
                    ...workerOrderButtonProps,
                    href: `${getRealmBySubscription(subscription)}/work_order_detail.do?id=${
                      prop?.workOrderZID
                    }`,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }}
                >
                  <ExitToAppIcon />
                </Button>
              </Tooltip>
              {(prop?.workOrderStatus === "Pending Approval" ||
                prop?.workOrderStatus === "Confirmed") && (
                <Tooltip id='tooltip-worker' title="View worker's profile">
                  <Link
                    to={`../../workers/profile?type=workOrder&id=${prop?.workOrderID}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button
                      {...{
                        ...workerOrderButtonProps,
                        color: "primary",
                      }}
                    >
                      <ArrowForwardIos />
                    </Button>
                  </Link>
                </Tooltip>
              )}
            </div>
          ),
        }))
      );
    }
  }, [workerOrders]);

  useEffect(() => {
    if (workers?.records)
      setWorkersFormatted(
        workers?.records?.map((prop: any, key: any) => ({
          id: key,
          workerID: prop.workerID ?? "",
          workOrderID: prop.workOrderID ?? "",
          vendor:
            prop.vendorNumber && prop.vendorName ? `${prop.vendorNumber} - ${prop.vendorName}` : "",
          firstName: prop.firstName,
          lastName: prop.lastName,
          status: getBadgeFromEntityStatus(prop.status, t) ?? "",
          actions: (
            <div className='actions-right'>
              <Tooltip id='tooltip-top' title='View on Fieldglass'>
                <Button
                  {...{
                    ...workerOrderButtonProps,
                    href: `${getRealmBySubscription(subscription)}/worker.do?id=${
                      prop?.workerUpload
                    }`,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }}
                >
                  <ExitToAppIcon />
                </Button>
              </Tooltip>
              <Tooltip id='tooltip-worker' title="View worker's profile">
                <Link
                  to={`../../workers/profile?type=worker&id=${prop.workerID}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Button
                    {...{
                      ...workerOrderButtonProps,
                      color: "primary",
                    }}
                  >
                    <ArrowForwardIos />
                  </Button>
                </Link>
              </Tooltip>
            </div>
          ),
        }))
      );
  }, [workers]);

  const tabs: Tab[] = [
    {
      id: "worker-order",
      title: "work orders",
      hasCounter: workerOrders?.total_count ?? 0,
    },
    {
      id: "worker",
      title: "workers",
      hasCounter: workers?.total_count ?? 0,
    },
  ];

  return (
    <GridItem {...gridItemProps}>
      {openApproveWorkOrderDialogModal.open && (
        <ApproveWorkOrderDialog
          open={openApproveWorkOrderDialogModal.open}
          handleClose={() => setOpenApproveWorkOrderDialogModal({ open: false })}
          workOrder={openApproveWorkOrderDialogModal}
        />
      )}
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <Group />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Work Orders / Workers</h4>
        </CardHeader>
        <CardBody>
          <TabSystem tabs={tabs}>
            <TabContent value='worker-order' variant='card'>
              {isWorkerOrdersLoading ? (
                <CustomSkeleton numberOfLines={10} height={50} />
              ) : (
                <>
                  <FormGroup style={{ margin: "10px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={watch("showAllWorkOrders")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 26,
                            },
                          }}
                          {...register("showAllWorkOrders")}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: "1rem",
                            color: "rgba(0, 0, 0, 0.67)",
                          }}
                        >
                          Show All Work Orders
                        </Typography>
                      }
                      labelPlacement='end'
                    />
                  </FormGroup>
                  <EntityTable
                    columns={columnsWorkOrder}
                    setTableState={setWorkOrderTable}
                    tableState={workOrderTable}
                    pageCount={workerOrders?.page_count}
                    data={workOrdersFormatted}
                  />
                </>
              )}
            </TabContent>
            <TabContent value='worker' variant='card'>
              {isWorkersLoading ? (
                <CustomSkeleton numberOfLines={10} height={50} />
              ) : (
                <EntityTable
                  columns={columnsWorker}
                  setTableState={setWorkerTable}
                  tableState={workerTable}
                  pageCount={workers?.page_count}
                  data={workersFormatted}
                />
              )}
            </TabContent>
          </TabSystem>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default WorkerTable;
