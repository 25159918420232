import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import AdminLayout from "layouts/Admin";
import { angloRoutes } from "routes";
import Suppliers from "views/Pages/Suppliers/Suppliers";
import SupplierProfile from "views/Pages/Suppliers/Profile";
import SowProfile from "views/Pages/SOW/Profile";
import SowTable from "components/SOW/SowTable";
import NoAccessPermission from "views/Pages/Error/NoAccessPermission";
import NotFound from "views/Pages/Error/NotFound";
import Profile from "views/Pages/Workers/Profile";
import PhysicalCard from "views/Pages/PhysicalCard/PhysicalCard";
import UserProfile from "views/Pages/UserProfile/UserProfile";
import Workers from "views/Pages/Workers/Workers";
import ProfileNotFound from "views/Pages/Workers/ProfileNotFound";
import { Dashboard } from "views/Pages/Dashboard/Dashboard";
import Users from "views/Pages/Users";

const uverifyAngloBasic: RouteObject[] = [
  {
    path: "/",
    element: <AdminLayout routes={angloRoutes} />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "suppliers",
        children: [
          {
            index: true,
            element: <Navigate to='/app/suppliers/list' replace />,
          },
          {
            path: "list",
            element: <Suppliers />,
          },
          {
            path: "profile",
            element: <SupplierProfile />,
          },
        ],
      },
      {
        path: "sows",
        children: [
          {
            index: true,
            element: <Navigate to='/app/sows/list' replace />,
          },
          {
            path: "list",
            element: <SowTable />,
          },
          {
            path: "profile",
            element: <SowProfile />,
          },
        ],
      },
      {
        path: "workers",
        children: [
          {
            index: true,
            element: <Navigate to='/app/workers/list' replace />,
          },
          {
            path: "list",
            element: <Workers />,
          },
          {
            path: "profile",
            children: [
              { index: true, element: <Profile /> },
              { path: "not-found", element: <ProfileNotFound /> },
            ],
          },
        ],
      },
      {
        path: "users",
        element: <Users/>,
        index: true,
      },
      {
        path: "no-access-permission",
        element: <NoAccessPermission />,
        index: true,
      },
      {
        path: "*",
        element: <NotFound />,
        index: true,
      },
      {
        path: "physical-card",
        element: <PhysicalCard />,
        index: true,
      },
      {
        path: "profile",
        element: <UserProfile />,
        index: true,
      },
    ],
  },
];

export default function AdminRoutes() {
  const route = useRoutes(uverifyAngloBasic);

  return route;
}
