import { useEffect, useState } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { useSensitiveData } from "services/input-core/sensitive-data/get-all-sensitive-data";
import { getConfigFromClientConfig } from "services/input-core/clientConfig/get-client-config";
import { ProfileType } from "services/input-core/sensitive-data/type";
import { useForm } from "react-hook-form";
import { MenuItem, TextField, Button } from "@material-ui/core";

import { useCreateSensitiveData } from "services/input-core/sensitive-data/create-sensitive-data";
import { useSubscription } from "hooks/useSubscription";
import Skeleton from "react-loading-skeleton";
import Snackbar from "components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import { useAuth } from "hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";

interface SnackbarPayload {
  color: "success" | "warning" | "danger" | "info";
  message: string;
}

type OrepFormProps = {
  workOrder?: any;
  workOrderId?: string;
  type?: "workOrder" | "worker";
  canProcess?: boolean;
};

export function WOOrepForm({ workOrder, workOrderId, type, canProcess }: OrepFormProps) {
  const refId = new URLSearchParams(window.location.search).get("id");
  const formIdentifier: ProfileType = "WORK_ORDER_APPROVE_OREP";
  const { hasRealmRole } = useAuth();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "success",
  });

  const showNotification = (payload: SnackbarPayload) => {
    setNotification({
      open: true,
      message: payload.message,
      color: payload.color,
    });
  };

  const { subscription } = useSubscription();
  const [clientConfig, setClientConfig] = useState();
  const queryClient = useQueryClient();

  useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  const sensitiveDataOptions = getConfigFromClientConfig(clientConfig, formIdentifier);

  const fields = sensitiveDataOptions?.steps?.reduce((acc, step) => [...acc, ...step.fields], []);

  const { data: sensitiveDataFromSOW, isLoading: isLoadingSensitiveData } = useSensitiveData({
    formIdentifier,
    refId: type === "worker" ? workOrderId : refId,
  });

  const { mutate, isLoading: isSendingData } = useCreateSensitiveData();

  const { register, watch, getValues, reset } = useForm();

  const formData = getValues();

  const getNameByDisplayName = (displayName: string) => {
    return fields?.find((field) => field.displayName.default === displayName)?.name;
  };

  useEffect(() => {
    if (sensitiveDataFromSOW?.length > 0) {
      const loadedState: any = sensitiveDataFromSOW.reduce((prev, current) => {
        return {
          ...prev,
          [getNameByDisplayName(current.label.default)]: current._id,
        };
      }, {});

      reset(loadedState);
    }
  }, [sensitiveDataFromSOW]);

  const getFieldByFieldName = (fieldName: string) => {
    return fields?.find((field: any) => field.name === fieldName);
  };

  const buttonProps: any = {
    color: "primary",
    onClick: () => handleSubmit(),
    disabled: isSendingData || (type === "worker" && canProcess === false),
    variant: "contained",
  };

  const handleSubmit = () => {
    const formDataFormatted = Object.keys(formData).map((key) => ({
      name: key,
      value: formData[key],
    }));

    mutate(
      {
        refId: type === "worker" ? workOrderId : refId,
        subscriptionId: subscription,
        formIdentifier,
        formData: formDataFormatted,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sensitiveData", formIdentifier, refId]);
          queryClient.invalidateQueries(["WorkOrder", refId]);
          showNotification({
            color: "success",
            message: "Saved successfully!",
          });
        },
        onError: (error: any) => {
          showNotification({
            color: "danger",
            message: error.response.data.error.checkIfFormsAreFilled,
          });
        },
      }
    );
  };

  return (
    <>
      <Snackbar
        place='bc'
        color={notification.color}
        icon={AddAlert}
        message={notification.message}
        open={notification.open}
        closeNotification={() =>
          setNotification({
            open: false,
            message: "",
            color: undefined,
          })
        }
        close
      />
      <Card>
        <CardHeader color='warning' icon>
          <CardIcon color='warning'>
            <BusinessCenterIcon />
          </CardIcon>
          <h4 style={{ color: "black" }}>{"OREP"}</h4>
        </CardHeader>
        <CardBody>
          {isLoadingSensitiveData ? (
            <Skeleton />
          ) : (
            <>
              <TextField
                id='area'
                label='OREP Field'
                variant='standard'
                style={{ width: "100%", marginBottom: "10px" }}
                disabled={!hasRealmRole(["Human Resources - Org Data Structure"])}
                select
                value={watch("orep") || ""}
                {...register("orep")}
              >
                {getFieldByFieldName("orep")?.availableValues?.map((availableValue: any) => (
                  <MenuItem key={availableValue.value} value={availableValue.value}>
                    {availableValue.displayName.default}
                  </MenuItem>
                ))}
              </TextField>
              {hasRealmRole(["Human Resources - Org Data Structure"]) && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button {...buttonProps}>SAVE</Button>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
