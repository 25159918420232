// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssigmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import FolderIcon from "@material-ui/icons/Folder";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Group from "@material-ui/icons/Group";
import { Search, PersonAdd } from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";

const adminRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    layout: "/app",
  },
  {
    path: "/suppliers/list",
    name: "suppliers",
    rtlName: "لوحة القيادة",
    icon: BusinessIcon,
    layout: "/app",
  },
  {
    path: "/workers/list",
    name: "workers",
    rtlName: "صفحات",
    icon: PersonIcon,
    layout: "/app",
  },
  {
    path: "/sows/list",
    name: "sows",
    rtlName: "صفحات",
    icon: AssigmentIcon,
    layout: "/app",
    feature: "sowProfile",
  },
  {
    path: "/analysis/list",
    name: "analyzes",
    rtlName: "صفحات",
    icon: Search,
    layout: "/app",
  },
  {
    path: "/documents/list",
    name: "documents",
    rtlName: "صفحات",
    icon: FolderIcon,
    layout: "/app",
  },
  {
    path: "/relatorio/list",
    name: "reports",
    rtlName: "صفحات",
    icon: AssessmentIcon,
    layout: "/app",
  },
  {
    path: "/users",
    name: "users",
    rtlName: "صفحات",
    icon: PersonAdd,
    layout: "/app",
  },

  {
    path: "/suppliers/profile",
    name: "supplier-profile",
    rtlName: "لوحة القيادة",
    icon: Group,
    layout: "/app",
    invisible: true,
  },
];

const supplierRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    layout: "/app",
  },
  {
    path: "/suppliers/profile",
    name: "my-profile",
    rtlName: "لوحة القيادة",
    icon: BusinessIcon,
    layout: "/app",
  },
  {
    path: "/sows/list",
    name: "sows",
    rtlName: "صفحات",
    icon: AssigmentIcon,
    layout: "/app",
    feature: "sowProfile",
  },
  {
    path: "/workers/list",
    name: "workers",
    rtlName: "صفحات",
    icon: PersonIcon,
    layout: "/app",
  },
  {
    path: "/analysis/list",
    name: "analyzes",
    rtlName: "صفحات",
    icon: Search,
    layout: "/app",
  },
  {
    path: "/users",
    name: "users",
    rtlName: "صفحات",
    icon: PersonAdd,
    layout: "/app",
  },

  {
    path: "/documents/list",
    name: "documents",
    rtlName: "صفحات",
    icon: FolderIcon,
    layout: "/app",
  },
  {
    path: "/data/upload",
    name: "batch-upload",
    rtlName: "صفحات",
    icon: UploadIcon,
    layout: "/app",
    feature: "cargaMassiva",
  },
];

const workerRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    layout: "/app",
  },
  {
    path: "/workers/profile",
    name: "my-profile",
    rtlName: "لوحة القيادة",
    icon: Group,
    layout: "/app",
  },
  {
    path: "/analysis/list",
    name: "analyzes",
    rtlName: "صفحات",
    icon: Search,
    layout: "/app",
  },
];

const angloRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    layout: "/app",
  },
  {
    path: "/suppliers/list",
    name: "suppliers",
    rtlName: "لوحة القيادة",
    icon: BusinessIcon,
    layout: "/app",
  },
  {
    path: "/sows/list",
    name: "sows",
    rtlName: "صفحات",
    icon: AssigmentIcon,
    layout: "/app",
  },
  {
    path: "/users",
    name: "users",
    rtlName: "صفحات",
    icon: PersonAdd,
    layout: "/app",
  },
  {
    path: "/workers/list",
    name: "workers",
    rtlName: "صفحات",
    icon: PersonIcon,
    layout: "/app",
  },
];

export { adminRoutes, supplierRoutes, workerRoutes, angloRoutes };
