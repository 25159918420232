import React, { useEffect, useMemo, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import moment from "moment";
// core components

import GridContainer from "components/Grid/GridContainer.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableChartIcon from "@material-ui/icons/TableChart";
import { getRealmBySubscription } from "helpers/SubscriptionHelper";
import ApprovalIcon from "@material-ui/icons/AssignmentTurnedIn";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import Badge from "components/Badge/Badge";
import { AddAlert, CheckOutlined, Close, Search, Send } from "@material-ui/icons";
import InfoArea from "components/InfoArea/InfoArea";
import { Tooltip, Divider, Card } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar";

import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useSubscription } from "hooks/useSubscription";
import { generateResultBadgeHelper } from "helpers/generateResultBadgeHelper";
import TabSystem from "components/TabsRadix/TabSystem";
import TabContent from "components/TabsRadix/TabContent";
import { useSensitiveData } from "services/input-core/sensitive-data/get-all-sensitive-data";
import WorkersFromSowTable from "components/Worker/WorkersFromSowTable";
import Wizard from "components/Wizard";
import { useCreateSensitiveData } from "services/input-core/sensitive-data/create-sensitive-data";
import { getConfigFromClientConfig } from "services/input-core/clientConfig/get-client-config";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useSOWQuery } from "services/input-core/sows/get-sow-by-id";
import DecisionTreeWizard from "components/DecisionTreeWizard";
import FieldglassInfo from "components/FieldglassInfo/FieldglassInfo";
import { sowRemap } from "helpers/SowRemap";

import ActivityItemsSow from "components/ActivityItemsTable/ActivityItemsSow";
import AnalysisModal from "components/Analysis/AnalysisModal";
import GridItemTS from "components/Grid/GridItemTS";
import ButtonTS from "components/CustomButtons/ButtonTS";
import ProcessTable from "components/ProcessTable/ProcessTable";
import ManualValidationModal from "components/ManualValidationModal/ManualValidationModal";
import { useSowProcessTableQuery } from "services/input-core/sows/get-sow-process-table";
import { WorkItemResultEnum } from "utils/@types";

const useStyles = makeStyles(styles as any);

const tabs = [
  {
    id: "sow1",
    title: "sow-general",
  },
];

const drawBadgeBasedOnStatus = (workItem: any, t: any) => {
  if (
    workItem.status === WorkItemResultEnum.HOMOLOGADO_MANUALMENTE ||
    workItem.status === WorkItemResultEnum.REJEITADO_MANUALMENTE
  ) {
    return (
      <Badge color='info'>
        <Tooltip
          title={
            <div>
              <div>{workItem.manualValidation.approved ? t("approved") : t("rejected")}</div>
              <div>{`${t("observation")}: ${workItem.manualValidation.observation}`}</div>
              <div>{`${t("expiration-date")}: ${moment(
                workItem.manualValidation.expirationDate
              ).format("DD/MM/YYYY")}`}</div>
              <div>{`${t("approved-by")}: ${workItem.manualValidation.user}`}</div>
            </div>
          }
        >
          <span>{workItem.status}</span>
        </Tooltip>
      </Badge>
    );
  }

  return <Badge color='info'>{workItem.status}</Badge>;
};

export default function Profile() {
  const classes = useStyles();
  const ref = useRef(null);
  const { t } = useTranslation("worker-profile");
  const refId = new URLSearchParams(window.location.search).get("id");

  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    color: "primary",
  });

  const [modal, setModal] = React.useState(false);

  const [analyzedContent, setAnalyzedContent] = React.useState([]);

  const [pendingLength] = React.useState(0);
  const [sentLength] = React.useState(0);
  const [homologatedLength] = React.useState(0);
  const [deniedLength] = React.useState(0);
  const [currentSOW, setCurrentSOW] = React.useState("");

  const [showWizard, setShowWizard] = React.useState(false);
  const [showDecisionTreeWizard, setShowDecisionTreeWizard] = React.useState(false);

  const [openManualValidationModal, setOpenManualValidationModal] = React.useState(false);

  const [workItemSelected, setWorkItemSelected] = React.useState("");

  const clientData = JSON.parse(localStorage.getItem("clientConfig"));

  const wizardConfig = getConfigFromClientConfig(clientData, "SOW");

  const { data: sowProcesses, isFetching: isFetchingSowProcess } = useSowProcessTableQuery(refId);

  const fields = useMemo(
    () => wizardConfig?.steps?.reduce((acc, step) => [...acc, ...step.fields], []),
    [wizardConfig]
  );

  const decisionTreeConfig = fields?.find(
    (field) => field.name === "contractorCategory"
  ).availablePath;

  const { subscription } = useSubscription();
  const sensitiveDataPayload = {
    formIdentifier: "SOW",
    refId: refId,
  };

  const { refetch: refetchSensitiveData } = useSensitiveData(sensitiveDataPayload);

  const { data: sow } = useSOWQuery(currentSOW);
  const queryClient = useQueryClient();

  const { mutate } = useCreateSensitiveData({
    onSuccess: (data: any, variables: any) => {
      setNotification({
        open: true,
        color: "success",
        message: "Sensitive data created successfully",
      });

      queryClient.invalidateQueries(["sensitiveData", ...Object.values(variables)]);
      refetchSensitiveData();
    },
  });

  const onSubmit = (data: any) => {
    mutate({
      subscriptionId: subscription,
      refId: currentSOW,
      formIdentifier: "SOW",
      formData: Object.keys(data).map((key) => ({
        name: key,
        value: data[key],
      })),
    });

    setShowWizard(false);
    localStorage.removeItem("contractorCategory" + refId);
  };

  const decisionTreeSubmit = (data: any) => {
    localStorage.setItem(
      "contractorCategory" + refId,

      data.value
    );

    setShowDecisionTreeWizard(false);
  };

  useEffect(() => {
    if (subscription) {
      const queryParams = new URLSearchParams(window.location.search);
      ref.current.continuousStart();
      setCurrentSOW(queryParams.get("id"));
    }
  }, [subscription]);

  const drawProcessTableRow = (workItem: any) => ({
    code: workItem.type ? workItem.type.toUpperCase() : "Activity Item sin código",
    documentName: workItem?.documents ? (
      workItem?.documents.length
    ) : (
      <Badge color='warning'>No hay documentos analizados</Badge>
    ),
    status: drawBadgeBasedOnStatus(workItem, t),
    statusString: workItem.status,
    result: generateResultBadgeHelper(workItem),
    resultString: workItem.result,
    date: moment(workItem.updatedAt).format("DD/MM/YYYY HH:mm"),
    actions: (
      <div className='actions-right'>
        {workItem.documents
          ? workItem.documents.map((doc: any) => (
              <Tooltip title={doc.name}>
                <ButtonTS
                  justIcon
                  round
                  color='info'
                  href={doc.url}
                  download
                  disabled={!doc.url}
                  target='_blank'
                >
                  <CloudDownloadIcon />
                </ButtonTS>
              </Tooltip>
            ))
          : ""}
        <ButtonTS
          justIcon
          round
          color='success'
          target='_blank'
          onClick={() => handleAnalyzeDocument(workItem?.analyzes)}
          disabled={!workItem?.analyzes?.length}
        >
          <TableChartIcon />
        </ButtonTS>
        {!workItem.approved && workItem.status !== "REJEITADO MANUALMENTE" && (
          <ButtonTS
            justIcon
            round
            color='info'
            target='_blank'
            onClick={() => {
              setWorkItemSelected(workItem._id);
              setOpenManualValidationModal(true);
            }}
          >
            <ApprovalIcon />
          </ButtonTS>
        )}
      </div>
    ),
  });

  const handleAnalyzeDocument = (analyzes: any) => {
    setAnalyzedContent(analyzes);
    setModal(true);
  };

  const onManualValidationFail = () => {
    setNotification({
      open: true,
      color: "danger",
      message: t("manual-validation-fail"),
    });
  };

  const onManualValidationSuccess = () => {
    setNotification({
      open: true,
      color: "success",
      message: t("manual-validation-success"),
    });
  };

  const handleQuitModal = (state: any) => {
    setShowWizard(state);
    localStorage.removeItem("contractorCategory" + refId);
  };

  return (
    <div>
      <LoadingBar color='#ff9800' ref={ref} />

      <Snackbar
        place='bc'
        color={notification.color}
        icon={AddAlert}
        message={notification.message}
        open={notification.open}
        closeNotification={() => setNotification({ open: false, message: "", color: "primary" })}
        close
      />

      {modal && <AnalysisModal analysis={analyzedContent} setModal={setModal} modal={modal} />}

      {openManualValidationModal && (
        <ManualValidationModal
          open={openManualValidationModal}
          OnSuccess={onManualValidationSuccess}
          OnFail={onManualValidationFail}
          workOrderOrWorkerID={refId}
          setOpen={setOpenManualValidationModal}
          workItemID={workItemSelected}
        />
      )}

      {showWizard && wizardConfig && (
        <Wizard
          title='SOW'
          caption='HR Requirements Form'
          formSubmit={onSubmit}
          active={showWizard}
          handleActive={handleQuitModal}
          wizardData={wizardConfig}
        />
      )}

      {decisionTreeConfig && (
        <DecisionTreeWizard
          activeModal={showDecisionTreeWizard}
          handleActiveModal={setShowDecisionTreeWizard}
          title='Classifying Consultants & Contractors'
          handleSubmit={decisionTreeSubmit}
          decisionTreeData={decisionTreeConfig}
        />
      )}
      <GridContainer>
        <GridItemTS xs={12} sm={12} md={9} style={{ marginBottom: "0px" }}>
          <TabSystem tabs={tabs}>
            <TabContent value='sow1'>
              <GridContainer>
                <GridItemTS xs={12}>
                  <Card style={{ paddingTop: 20 }}>
                    <CardHeader color='rose' icon>
                      <CardIcon color='rose'>
                        <PermIdentity />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>{t("profile")}</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItemTS xs={12} sm={12} md={5}>
                          {sow?.sowName !== undefined ? (
                            <CustomInput
                              id='company-disabled'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: sow?.sowName,
                                disabled: true,
                              }}
                            />
                          ) : (
                            <Skeleton />
                          )}
                        </GridItemTS>
                        <GridItemTS xs={12} sm={12} md={3}>
                          {sow?.sowID !== undefined ? (
                            <CustomInput
                              id='username'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: sow?.sowID,
                                disabled: true,
                              }}
                            />
                          ) : (
                            <Skeleton />
                          )}
                        </GridItemTS>
                        <GridItemTS xs={12} sm={12} md={4}>
                          {sow?.supplierName !== undefined ? (
                            <CustomInput
                              id='email-address'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: sow?.supplierName,
                                disabled: true,
                              }}
                            />
                          ) : (
                            <Skeleton />
                          )}
                        </GridItemTS>

                        <GridItemTS xs={12} sm={12} md={3}>
                          <InfoArea
                            title={t("pending-submission")}
                            description={`${pendingLength} ${t("wait-submit")}.`}
                            icon={Send}
                            iconColor='warning'
                          />
                        </GridItemTS>
                        <GridItemTS xs={12} sm={12} md={3}>
                          <InfoArea
                            title={t("analysis")}
                            description={`${sentLength} ${t("documents-analysis")}.`}
                            icon={Search}
                            iconColor='info'
                          />
                        </GridItemTS>
                        <GridItemTS xs={12} sm={12} md={3}>
                          <InfoArea
                            title={t("worker-homologated")}
                            description={`${homologatedLength} ${t("approved-documents")}.`}
                            icon={CheckOutlined}
                            iconColor='success'
                          />
                        </GridItemTS>
                        <GridItemTS xs={12} sm={12} md={3}>
                          <InfoArea
                            title={t("not-homologated")}
                            description={`${deniedLength} ${t("unapproved-documents")}.`}
                            icon={Close}
                            iconColor='danger'
                          />
                        </GridItemTS>
                      </GridContainer>
                      <Clearfix />
                    </CardBody>
                  </Card>
                </GridItemTS>

                <GridItemTS xs={12}>
                  <WorkersFromSowTable omit={["email", "vendorName", "vendorCode"]} />
                </GridItemTS>

                <GridItemTS xs={12}>
                  <ActivityItemsSow />
                </GridItemTS>

                <GridItemTS xs={12}>
                  <ProcessTable
                    classes={classes}
                    processData={
                      sowProcesses?.map((sowProcess: any) => drawProcessTableRow(sowProcess)) ?? []
                    }
                    isProcessLoading={isFetchingSowProcess}
                  />
                </GridItemTS>
              </GridContainer>
            </TabContent>
          </TabSystem>
        </GridItemTS>
        <GridItemTS xs={12} sm={12} md={3}>
          <Card>
            <CardBody profile>
              <div>
                <h4 className={classes.cardTitle}>{sow?.sowName}</h4>
                {sow?.status !== undefined ? (
                  <Badge color='info'>{sow?.status}</Badge>
                ) : (
                  <Skeleton />
                )}
              </div>
              <div style={{ marginTop: 24 }}>
                <ButtonTS
                  color='warning'
                  round
                  href={`${getRealmBySubscription(subscription)}/pay_terms_request.do?id=${
                    sow?.zIdentifier
                  }`}
                  target='_blank'
                  disabled={!getRealmBySubscription(subscription)}
                  fullWidth
                >
                  {t("access-fieldglass")}
                </ButtonTS>
              </div>
              <Divider style={{ marginTop: "24px" }} />
              <FieldglassInfo title='SOW Info' fieldGlassData={sow} remapper={sowRemap} />
            </CardBody>
          </Card>
        </GridItemTS>
      </GridContainer>
    </div>
  );
}
