import { Search } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridItem from "components/Grid/GridItemTS";
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "hooks/useSubscription";
import ButtonTS from "components/CustomButtons/ButtonTS";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BusinessIcon from "@material-ui/icons/Business";
import Badge from "components/Badge/Badge";
import { useEffect, useRef, useState } from "react";
import EntityTable from "components/ReactTable/CustomTable";
import { useSowsQuery } from "services/input-core/sows/get-all-sows";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { ColumnDef } from "@tanstack/react-table";
import { sortDateWithMoment } from "helpers/customSortMethods";
import LoadingBar from "react-top-loading-bar";
import GridContainer from "components/Grid/GridContainer";
import { Tooltip } from "@mui/material";
import { getRealmBySubscription } from "helpers/SubscriptionHelper";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { useTranslation } from "react-i18next";
import { SowStatusEnum } from "utils/@types";
import { Link } from "react-router-dom";

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const sowButtonProps: any = {
  justIcon: true,
  round: true,
  color: "warning",
  size: "sm",
};

const useStyles = makeStyles(styles as any);

const columnsSows: ColumnDef<any, any>[] = [
  {
    header: "Status",
    footer: (props: any) => props.column.id,
    accessorKey: "status",
    cell: (info: any) => info.getValue(),
    enableSorting: false,
  },
  {
    footer: (props: any) => props.column.id,
    header: "ID",
    accessorKey: "sowID",
    cell: (info: any) => info.getValue(),
  },
  {
    header: "Name",
    footer: (props: any) => props.column.id,
    accessorKey: "sowName",
    cell: (info: any) => info.getValue(),
  },
  {
    header: "Supplier",
    footer: (props: any) => props.column.id,
    accessorKey: "sowSupplier",
    cell: (info: any) => info.getValue(),
  },
  {
    header: "Start",
    footer: (props: any) => props.column.id,
    accessorKey: "sowStartDate",
    cell: (info: any) => info.getValue(),
    sortingFn: sortDateWithMoment,
  },
  {
    header: "End",
    footer: (props: any) => props.column.id,
    accessorKey: "sowEndDate",
    cell: (info: any) => info.getValue(),
    sortingFn: sortDateWithMoment,
  },
  {
    header: "Actions",
    footer: (props: any) => props.column.id,
    accessorKey: "actions",
    cell: (info: any) => info.getValue(),
    enableColumnFilter: false,
  },
];

function getBadgeFromStatus(status: string, t: any) {
  switch (status) {
    case SowStatusEnum.APPROVAL_PAUSED:
      return <Badge {...{ color: "info" }}>{t(status)}</Badge>;
    case SowStatusEnum.DRAFT:
    case SowStatusEnum.PENDING_APPROVAL:
    case SowStatusEnum.PENDING_RESPONSE:
    case SowStatusEnum.RESPONSE_PENDING_APPROVAL:
      return <Badge {...{ color: "warning" }}>{t(status)}</Badge>;
    case SowStatusEnum.CLOSED:
    case SowStatusEnum.RESPONSE_REJECTED:
      return <Badge {...{ color: "danger" }}>{t(status)}</Badge>;
    case SowStatusEnum.APPROVED:
      return <Badge {...{ color: "success" }}>{t(status)}</Badge>;
    default:
      return <Badge {...{ color: "info" }}>{t(status)}</Badge>;
  }
}

const getFilters = (filters: any) => {
  const object: { [key: string]: any } = {};

  filters?.forEach((filter: any) => {
    if (filter.id === "sowSupplier") {
      object["filter[supplier]"] = filter.value;
    } else {
      object[`filter[${filter.id}]`] = filter.value;
    }
  });

  return { ...object };
};

const getSorting = (sorting: any) => {
  const object: { [key: string]: any } = {};

  if (sorting?.length > 0)
    sorting?.forEach((sort: any) => (object[`sort[${sort.id}]`] = sort.desc ? "desc" : "asc"));

  return { ...object };
};

export default function SowTable() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation("worker-profile");
  const ref = useRef();
  const { subscription } = useSubscription();

  const queryParams = new URLSearchParams(window.location.search);
  const supplierCode = queryParams.get("id") ?? "";

  const [sowsFormatted, setSowsFormatted] = useState([]);
  const [sowTable, setSowTable] = useState({
    pageIndex: 0,
    pageSize: 10,
    columnFilters: [],
    sorting: [],
  });

  const loadSupplier = (supplierCode: string) => {
    navigate("/app/suppliers/profile?type=supplier&id=" + supplierCode);
  };

  const { data: sows, isFetching: isSowsLoading } = useSowsQuery(subscription, {
    "page[index]": sowTable.pageIndex,
    "page[size]": sowTable.pageSize,
    "filter[vendorNumber]": supplierCode ?? "",
    ...getFilters(sowTable.columnFilters),
    ...getSorting(sowTable.sorting),
  } as any);

  useEffect(() => {
    if (sows?.records) {
      setSowsFormatted(
        sows?.records?.map((sowItem: any, key: any) => ({
          id: key,
          status: getBadgeFromStatus(sowItem?.status, t) ?? "",
          sowID: sowItem?.sowID ?? " - ",
          sowName: sowItem?.sowName ?? " - ",
          sowSupplier: sowItem?.supplierName ?? " - ",
          sowStartDate: sowItem?.startDate ?? " - ",
          sowEndDate: sowItem?.endDate ?? " - ",
          actions: (
            <div className='actions-right'>
              <ButtonTS
                {...{
                  ...sowButtonProps,
                  color: "warning",
                  onClick: () => {
                    loadSupplier(sowItem?.supplierCode);
                  },
                }}
              >
                <BusinessIcon />
              </ButtonTS>
              <Tooltip id='tooltip-top' title='View on Fieldglass'>
                <ButtonTS
                  {...{
                    ...sowButtonProps,
                    href: `${getRealmBySubscription(subscription)}/pay_terms_request.do?id=${
                      sowItem?.zIdentifier
                    }`,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }}
                >
                  <ExitToAppIcon />
                </ButtonTS>
              </Tooltip>
              <Link
                to={"/app/sows/profile?id=" + sowItem?.sowID}
                target='_blank'
                rel='noopener noreferrer'
              >
                <ButtonTS
                  {...{
                    ...sowButtonProps,
                    color: "info",
                  }}
                >
                  <ArrowForwardIosIcon />
                </ButtonTS>
              </Link>
            </div>
          ),
        }))
      );
    }
  }, [sows]);

  return (
    <GridContainer>
      <LoadingBar color='#ff9800' ref={ref} />
      <GridItem xs={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <Search />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>SOWs</h4>
          </CardHeader>
          <CardBody>
            {isSowsLoading ? (
              <CustomSkeleton numberOfLines={10} height={50} />
            ) : (
              <EntityTable
                columns={columnsSows}
                setTableState={setSowTable}
                tableState={sowTable}
                pageCount={sows?.page_count}
                data={sowsFormatted ?? []}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
