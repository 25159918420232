import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { AvailableValuesProps, FieldsProps } from "services/input-core/clientConfig/type";
import InputSearcher from "components/InputSearcher";
import AutoCompleteCustom from "components/AutoCompleteCustom";
import { getStrategy, StrategyArgs } from "helpers/formStrategyHelper/strategy-mapper";
import CustomDialog from "components/CustomDialog/CustomDialog";
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography, TextField } from "@mui/material";
import FileUpload from "components/FileUpload";
import { getConfigFromClientConfig } from "services/input-core/clientConfig/get-client-config";
import { ProfileType } from "services/input-core/sensitive-data/type";
import ButtonTS from "components/CustomButtons/ButtonTS";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const stepsButtonStyle = {
  backgroundColor: "#2f87ee",
  color: "white",
  width: "140px",
};

type UploadDocumentModalProps = {
  formIdentifier: ProfileType;
  activityItem: any;
  active: boolean;
  handleActive: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (data: any, zIdentifier: string, formIdentifier: string) => void;
};

const getTitleByFormIdentifier = (formIdentifier: string) => {
  switch (formIdentifier) {
    case "WORK_ORDER_ID_DOCUMENT":
      return "Document Id";
    case "WORK_ORDER_PASSPORT":
      return "Passport";
    case "WORK_ORDER_DRIVER_LICENSE":
      return "Driver License";
    case "WORK_ORDER_WORK_PERMIT":
      return "Work Permit";

    default:
      return "";
  }
};

export default function UploadDocumentModal({
  formIdentifier,
  activityItem,
  active,
  handleActive,
  onSubmit,
}: UploadDocumentModalProps) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [atualPage, setAtualPage] = useState<number>(0);
  const [clientConfig, setClientConfig] = useState();
  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  const sensitiveDataOptions = getConfigFromClientConfig(clientConfig, formIdentifier);

  const hasFile = () => {
    if (
      atualPage === 0 &&
      sensitiveDataOptions?.steps?.length > 0 &&
      sensitiveDataOptions?.steps[0].fields?.length > 0
    )
      return watch(sensitiveDataOptions.steps[0].fields[0].name)?.length === 0;

    return false;
  };

  const resetForm = () => {
    reset();
    handleActive(false);
  };

  function applyStrategy<T>(strategyKey: string, args: any): T {
    const strategy: StrategyArgs = getStrategy(strategyKey);

    return strategy?.handle(args) as T;
  }

  useEffect(() => {
    resetField("liveAreaOfResidence");
    resetField("liveRegion");
    resetField("liveCountry");
  }, [watch("liveNextToOperation")]);

  useEffect(() => {
    if (watch("documentIdCountry") === "6400f69870aecc42c4dee057") {
      resetField("documentIdExpiryDate");
      setValue("documentIdUnexpired", true);
    }
    if (watch("documentIdUnexpired")) {
      resetField("documentIdExpiryDate");
    }
  }, [watch("documentIdCountry"), watch("documentIdExpiryDate")]);

  const handleButton = () => {
    if (atualPage === sensitiveDataOptions?.steps?.length - 1) {
      onSubmit(watch(), activityItem.zIdentifier, formIdentifier);
    } else {
      setAtualPage(atualPage + 1);
    }
  };

  const handleFileUpload = async (field: any, imageUrl: any) => {
    if (field?.strategyKey === "convertToBase64" && imageUrl.length > 0) {
      const base64Files: string[] = await applyStrategy(field?.strategyKey, [imageUrl]);
      setValue(field.name, base64Files);
    }
  };

  const renderFields = (field: FieldsProps) => {
    switch (field.fieldType) {
      case "searcher":
        if (field.displayName.default == "Country of Issue") {
          const orderCountry = field.availableValues.sort((x, y) => {
            const a = x.displayName.default.toUpperCase();
            const b = y.displayName.default.toUpperCase();

            return a == b ? 0 : a > b ? 1 : -1;
          });

          const auxFieldOrder = { ...field, availableValues: orderCountry };

          return (
            <InputSearcher
              fieldData={auxFieldOrder}
              disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
              hookFormWatch={watch}
              hookFormControl={control}
            />
          );
        } else {
          return (
            <InputSearcher
              fieldData={field}
              disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
              hookFormWatch={watch}
              hookFormControl={control}
            />
          );
        }

      case "string":
        return (
          <TextField
            id='standard-basic'
            variant='standard'
            label={field.displayName.default}
            value={watch(field.name)}
            required={field.required}
            style={{ width: "100%" }}
            disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
            {...register(field.name, { required: field.required })}
          />
        );

      case "select":
        return (
          <Controller
            render={({ field: { onChange } }) => (
              <AutoCompleteCustom
                label={field.displayName.default}
                disablePortal
                value={
                  field?.availableValues?.find((a) => a?.value === watch(field?.name))?.displayName
                    ?.default ?? ""
                }
                id='outlined-select-currency'
                disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
                onChange={(e: unknown, data: { id: unknown }) => {
                  if (typeof data !== "string") onChange(data?.id ?? undefined);
                }}
                options={field?.availableValues?.map((option: AvailableValuesProps) => ({
                  id: option.value,
                  label: option.displayName.default,
                }))}
                sx={{
                  width: "100% ",
                }}
              />
            )}
            rules={{
              required: field.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "multipleSelect":
        return (
          <Controller
            render={({ field: { onChange } }) => (
              <AutoCompleteCustom
                label={field.displayName.default}
                disablePortal
                value={
                  field?.availableValues?.find((a) => a?.value === watch(field?.name))?.displayName
                    ?.default ?? ""
                }
                id='outlined-select-currency'
                disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
                onChange={(e: unknown, data: { id: unknown }) => {
                  if (typeof data !== "string") onChange(data?.id ?? undefined);
                }}
                options={field?.availableValues?.map((option: AvailableValuesProps) => ({
                  id: option.value,
                  label: option.displayName.default,
                }))}
                sx={{
                  width: "100% ",
                }}
              />
            )}
            rules={{
              required: field.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "date":
        return (
          <Controller
            render={() => (
              <DatePicker
                label={field.displayName.default}
                value={watch(field.name) ? dayjs(watch(field.name)) : undefined}
                format='DD/MM/YYYY'
                disabled={
                  (field?.strategyArgsMapper &&
                    applyStrategy<boolean>(field.strategyKey, [
                      ...field.strategyArgsMapper.map((arg) => ({
                        currentValue: watch(arg.fieldName),
                        valueToMeet: arg.value,
                      })),
                    ])) ||
                  (field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)) ||
                  (field.name === "documentIdExpiryDate" && watch("documentIdUnexpired"))
                }
                onChange={(newValue) => setValue(field.name, newValue.toISOString())}
                sx={{ minHeight: 50 }}
              />
            )}
            rules={{
              required:
                field.name === "documentIdExpiryDate" && watch("documentIdUnexpired")
                  ? false
                  : field?.strategyArgsMapper
                  ? applyStrategy<boolean>(field.strategyKey, [
                      ...field.strategyArgsMapper.map((arg) => ({
                        currentValue: watch(arg.fieldName),
                        valueToMeet: arg.value,
                      })),
                    ])
                    ? field?.validateWhen && field?.validateWhen.length > 0
                      ? applyStrategy<boolean>("validateWhen", [
                          ...field?.validateWhen?.map((arg: any) => ({
                            currentValue: watch(arg.key),
                            valueToMeet: arg.value[0],
                          })),
                        ])
                      : field?.required
                    : field?.required
                  : field?.validateWhen && field?.validateWhen.length > 0
                  ? applyStrategy<boolean>("validateWhen", [
                      ...field?.validateWhen?.map((arg: any) => ({
                        currentValue: watch(arg.key),
                        valueToMeet: arg.value[0],
                      })),
                    ])
                  : field?.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "checkbox":
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    watch(field.name) || watch("documentIdCountry") === "6400f69870aecc42c4dee057"
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 30,
                    },
                  }}
                  {...register(field.name, {
                    onChange: (event: any) => {
                      if (event.target.checked)
                        applyStrategy<void>(field.strategyKey, [
                          ...field.strategyArgsMapper.map((arg) => arg.fieldName),
                          resetField,
                        ]);
                      setValue(field.name, event.target.checked);
                    },
                  })}
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: "1rem",
                    color: "rgba(0, 0, 0, 0.67)",
                  }}
                >
                  {field.displayName.default}
                </Typography>
              }
              disabled={field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)}
              labelPlacement='top'
            />
          </FormGroup>
        );

      case "fileUpload":
        return (
          <FileUpload
            fieldData={field}
            hookFormSetValue={setValue}
            currentFiles={currentFiles}
            setCurrentFiles={setCurrentFiles}
            onFileUpload={(imageUrl) => handleFileUpload(field, imageUrl)}
            label={"Click and select your document..."}
            type='wizard'
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <CustomDialog
      handleClose={resetForm}
      open={active}
      dialogTitle={
        <>
          <Typography>Work Order</Typography>
          <Typography style={{ fontWeight: 700, fontSize: 18 }}>Upload Activity Item</Typography>
          <Typography style={{ fontWeight: 700, fontSize: 18 }}>
            {getTitleByFormIdentifier(formIdentifier)}
          </Typography>
        </>
      }
      dialogContent={
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={1} style={{ width: "100%" }}>
              {sensitiveDataOptions?.steps[atualPage]?.fields
                ?.sort(
                  (fieldA: FieldsProps, fieldB: FieldsProps) => fieldA.sequence - fieldB.sequence
                )
                ?.map((field: FieldsProps) => (
                  <>
                    <Grid
                      key={field.name}
                      item
                      xs={field.gridSize ?? 12}
                      style={{
                        paddingTop: field.fieldType === "checkbox" ? 12 : 24,
                        display:
                          !field?.showWhen || field?.showWhen?.length === 0
                            ? "flex"
                            : field.showWhen[0].value[0] === watch(field.showWhen[0].key)
                            ? "flex"
                            : "none",
                        flexDirection: "column",
                      }}
                    >
                      {field.showWhen && field?.showWhen?.length > 0
                        ? field.showWhen[0].value[0] === watch(field.showWhen[0].key) &&
                          renderFields(field)
                        : renderFields(field)}
                      {errors[field.name] && (
                        <div key={field.name} style={{ width: "100%" }}>
                          This field is required
                        </div>
                      )}
                    </Grid>
                  </>
                ))}
            </Grid>
          </LocalizationProvider>
        </>
      }
      dialogAction={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            padding: 10,
          }}
        >
          {atualPage > 0 && (
            <ButtonTS
              variant='contained'
              style={{
                ...stepsButtonStyle,
                marginRight: "10px",
              }}
              onClick={() => setAtualPage(atualPage - 1)}
            >
              Back
            </ButtonTS>
          )}
          <ButtonTS
            variant='contained'
            style={stepsButtonStyle}
            disabled={hasFile()}
            onClick={() => {
              handleSubmit(handleButton)();
            }}
          >
            {atualPage === sensitiveDataOptions?.steps?.length - 1 ? "Finish" : "Next"}
          </ButtonTS>
        </div>
      }
    />
  );
}
